import React from 'react'
import {Flex, PageWrapper, Paragraph, Title, RightSection, LeftSection, Row} from '../components/UI'
import SEO from '../components/seo'
import Layout from '../components/layout'
import styled from 'styled-components'
import PageHeader from '../components/PageHeader'
import visual from '../images/visual-alkmaar.jpg'
import pattern from '../images/pattern-franchise.jpg'
import pieter from '../images/pieter_v2.jpeg'
import car from '../images/car.jpg'
import features from '../data/features.json'
import franchise from '../data/franchise.json'
import FranchiseStep from '../components/FranchiseStep'
import ContactBox from '../components/ContactBox'
import { breakpoints } from '../utils/helper'
import Features from '../components/Features'
import _ from 'lodash'
import {OutboundLink} from "gatsby-plugin-gtag";

const CustomTitle = styled(Title)`
    color: ${props => props.theme.colors.white};
    
    b {
        color: ${props => props.theme.colors.red};    
    }
`

const Box = styled(Flex)`
    margin: 90px 0 0px;
    flex-direction: column;
    align-items: center;
    
    @media ${breakpoints.tablet} {
        margin: 120px 0 0px;
        
        &.top {
            margin: 204px 0 0px;
        }
    }
`

const CenteredTitle = styled(Title)`
    text-align: left;
    margin-bottom: 54px;
    width: 100%;
    padding: 0 30px;
    
    @media ${breakpoints.tablet} {
        margin-bottom: 84px;
        text-align: center;
        width: 90%;
        padding: 0 0;
    }
    
    &.steps {
        @media ${breakpoints.tablet} {
            margin-bottom: 120px;
            max-width: 1000px;
        }
    }
`

const Image = styled.img`
    width: 100%;
`

const StyledParagraph = styled(Paragraph)`
    padding-top: 24px;
    
    @media ${breakpoints.tablet} {
        padding-top: 24px;
    }
    
    b {
        ${props => props.theme.fontStyle.menu};
    }
`

const Link = styled(OutboundLink)`
    color: ${props => props.theme.colors.red};
    ${props => props.theme.fontStyle.h4};
    text-decoration: none;
    margin-bottom: 12px;
`

const FranchisePage = ({location}) => {
    return (
        <Layout location={location}>
            <SEO title="Franchise | Wil jij een UP Carwash franchise starten?"
                 description="Heb jij als ondernemer de droom om een UP Carwash franchise starten? Ontdek alle voordelen die je als franchisenemer van onze wasstraat kunt verwachten."/>
            <PageHeader image={visual} backgroundColor="#333333" textColor="#FFFFFF" imageWidth="520px" pattern={pattern}>
                <CustomTitle>
                    Franchise-<br/>nemer worden?<br/><b>UP</b> to you!
                </CustomTitle>
            </PageHeader>
            <PageWrapper maxWidth="1070px">
                <Box className="top">
                    <CenteredTitle>Het bewezen<br/>carwash concept</CenteredTitle>
                    <Features data={_.get(features, 'features.franchise')}/>
                </Box>
                <Box>
                    <CenteredTitle className="steps">Wat kun je als franchisenemer van ons verwachten?</CenteredTitle>
                    {franchise.steps.map((item, i) =>
                        <FranchiseStep key={`${i}-${item.no}`} order={item.no} title={item.title} body={item.body}/>
                    )}
                </Box>
                <Row>
                    <LeftSection className="text">
                        <Title>De markt is aan het veranderen</Title>
                        <StyledParagraph>
                            Waar men vroeger gewend was om de auto voor de deur te wassen, kiest de nieuwe generatie bestuurders voor het gemak en de kwaliteit van de wasstraat. En dan het liefst met een ruime keuze aan wasprogramma's, voor het ultieme resultaat. UP Carwash is de eerste autowasformule die anticipeert op deze veranderende behoefte in de markt. Wat natuurlijk nooit verandert? Onze oprechte aandacht voor elke auto.
                        </StyledParagraph>
                    </LeftSection>
                    <RightSection className="med">
                        <Image src={car} alt="UP car"/>
                    </RightSection>
                </Row>
            </PageWrapper>
            <ContactBox>
                <img src={pieter} alt="CEO UP Carwash"/>
                <Flex>
                    <Title>Meer weten?</Title>
                    <StyledParagraph>
                        We drinken graag een kop koffie met je om te bespreken of we jouw ondernemersdroom waar kunnen maken.
                        <br/><br/>
                        Bel: <Link href="tel:+31857602403" rel="noopener">085-7602403</Link>
                        <br/>
                        Mail: <Link href="mailto:franchise@upcarwash.nl" rel="noopener">franchise@upcarwash.nl</Link>
                    </StyledParagraph>
                </Flex>
            </ContactBox>
        </Layout>
    )
}

export default FranchisePage
