import styled from 'styled-components'
import {Flex} from './UI'
import patternClub from '../images/pattern-contact.png'
import {breakpoints} from '../utils/helper'

const ContactBox = styled(Flex)`
    background: url(${patternClub}) 50% 50% no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    margin-top: 90px;
    align-content: flex-start;
    align-items: flex-start;
    padding-top: 0;
    overflow: hidden;
    
    @media ${breakpoints.tablet} {
        padding-top: 102px;
        height: 760px;
    }
    
    img {
        width: 488px;
        height: auto;
        position: relative;
        z-index: 10;
        display: none;
        
        @media ${breakpoints.tablet} {
            display: flex;
        }
    }
    
    div {
        width: 500px;
        background-color: ${props => props.theme.colors.black};
        flex-direction: column;
        padding: 60px 30px;
        margin: 0 0 0 0;
        
        @media ${breakpoints.tablet} {
            margin: 80px 0 0 -72px;
            padding: 60px 120px;
        }        
        
        @media ${breakpoints.large} {
            width: 650px;
            padding: 72px 120px 90px;
        }
        
        h1 {
            color: ${props => props.theme.colors.red};
        }
        
        p {
            color: ${props => props.theme.colors.white};
            width: 100%;
                
            @media ${breakpoints.tablet} {
                width: 80%;
            }
        }
    }
`

export default ContactBox
