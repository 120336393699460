import React from 'react'
import { Flex, Paragraph, Title, Title4, Row } from './UI'
import styled from 'styled-components'
import { breakpoints } from '../utils/helper'

const StepRow = styled(Row)`
    max-width: 640px;
    width: 100%;
    margin-bottom: 72px;
    padding: 0;
    flex-direction: row;
    padding: 0 30px;
    
    @media ${breakpoints.tablet} {    
        padding: 0 0;
    }
    
    @media ${breakpoints.large} {
        padding: 0 0;
    } 
`

const Column = styled(Flex)`
    flex-direction: column;
    max-width: 160px;
    
    @media ${breakpoints.mobileS} {
        max-width: 220px;
    }
    
    @media ${breakpoints.mobileM} {
        max-width: 240px;
    }
    
    @media ${breakpoints.tablet} {
        max-width: 420px;  
    }
    
    h4 {
        margin-bottom: 24px;
    }
`

const MegaTitle = styled(Title)`
    font-size: 60px;
    line-height: 60px;
    color: ${props => props.theme.colors.red};
    white-space: pre-wrap;

    @media ${breakpoints.tablet} {
        font-size: 100px;
        line-height: 100px;
    }
`

const FranchiseStep = ({order, title, body}) =>
    <StepRow>
        <MegaTitle>{order}</MegaTitle>
        <Column>
            <Title4>{title}</Title4>
            <Paragraph>{body}</Paragraph>
        </Column>
    </StepRow>

export default FranchiseStep
